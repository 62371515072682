var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('div', {
    staticClass: "text-right mt-3 mr-2"
  }, [_c('button', {
    directives: [{
      name: "b-modal",
      rawName: "v-b-modal.modal",
      modifiers: {
        "modal": true
      }
    }],
    staticClass: "btn btn-primary btn-sm"
  }, [_vm._v(" " + _vm._s(_vm.$t("add-new")) + " ")])]), _c('div', {
    staticClass: "table-responsive mt-3 mb-5"
  }, [_c('table', {
    staticClass: "table table-sm"
  }, [_c('thead', [_c('tr', [_c('th', [_vm._v(_vm._s(_vm.$t("index")))]), _c('th', [_vm._v(_vm._s(_vm.$t("name")))]), _c('th', [_vm._v(_vm._s(_vm.$t("mykad")))]), _c('th', [_vm._v(_vm._s(_vm.$t("relationship")))]), _c('th', [_vm._v(_vm._s(_vm.$t("part")))]), _c('th')])]), _c('tbody', [_vm._l(_vm.propertyReceivers, function (pr, index) {
    return _c('tr', {
      key: index
    }, [_c('td', [_vm._v(_vm._s(index + 1))]), _c('td', [_vm._v(_vm._s(pr.receiver.name))]), _c('td', [_vm._v(_vm._s(pr.receiver.ic_number))]), _c('td', [_vm._v(_vm._s(pr.receiver.relationship))]), _c('td', [_vm._v(_vm._s(pr.portion))]), _c('td', {
      staticClass: "text-right"
    }, [_vm.addnewreceiver ? _c('div', {
      staticClass: "btn-group-vertical",
      attrs: {
        "role": "group",
        "aria-label": "Vertical button group"
      }
    }, [_c('button', {
      staticClass: "btn btn-secondary btn-sm",
      on: {
        "click": function ($event) {
          return _vm.showUpdateModal(pr);
        }
      }
    }, [_vm._v(" " + _vm._s(_vm.$t("update")) + " ")]), _c('button', {
      staticClass: "btn btn-danger btn-sm",
      on: {
        "click": function ($event) {
          return _vm.removePropertyReceiver(pr);
        }
      }
    }, [_vm._v(" " + _vm._s(_vm.$t("remove")) + " ")])]) : _vm._e()])]);
  }), _vm.propertyReceivers && _vm.propertyReceivers.length == 0 ? _c('tr', [_c('td', {
    staticClass: "text-center",
    attrs: {
      "colspan": "6"
    }
  }, [_c('h5', {
    staticClass: "pt-5 pb-5"
  }, [_vm._v(_vm._s(_vm.$t("no-data")))])])]) : _vm._e()], 2)])]), _c('b-modal', {
    attrs: {
      "id": "modal",
      "title": "Tambah Penerima Harta",
      "ok-title": "Simpan",
      "cancel-title": "Batal"
    },
    on: {
      "ok": _vm.createPropertyReceiver
    }
  }, [_c('div', {
    staticClass: "modal-body"
  }, [_c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "form-group col-md-6"
  }, [_c('label', {
    attrs: {
      "for": "receiver_id"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("beneficiary")) + " "), _c('span', {
    staticClass: "text-danger"
  }, [_vm._v("*")]), _vm._v(" "), _c('img', {
    directives: [{
      name: "tippy",
      rawName: "v-tippy",
      value: {
        content: _vm.$t('reg-sect-f')
      },
      expression: "{ content: $t('reg-sect-f') }"
    }],
    staticClass: "mb-1",
    attrs: {
      "height": "15",
      "src": "/images/icon/info-button.png"
    }
  })]), _c('select', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.form.receiver_id,
      expression: "form.receiver_id"
    }],
    staticClass: "form-control",
    attrs: {
      "name": ""
    },
    on: {
      "change": [function ($event) {
        var $$selectedVal = Array.prototype.filter.call($event.target.options, function (o) {
          return o.selected;
        }).map(function (o) {
          var val = "_value" in o ? o._value : o.value;
          return val;
        });
        _vm.$set(_vm.form, "receiver_id", $event.target.multiple ? $$selectedVal : $$selectedVal[0]);
      }, function ($event) {
        return _vm.onSelectedReceiver($event);
      }]
    }
  }, [_c('option', {
    attrs: {
      "value": ""
    }
  }), _vm._l(_vm.receivers, function (receiver, index) {
    return _c('option', {
      key: index,
      domProps: {
        "value": receiver.id
      }
    }, [_vm._v(" " + _vm._s(receiver.name) + " ")]);
  })], 2)])]), _vm.form.receiver_id ? _c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-md-6 form-group"
  }, [_c('label', {
    attrs: {
      "for": ""
    }
  }, [_vm._v(_vm._s(_vm.$t("mykad")))]), _c('p', {
    staticClass: "form-control-static"
  }, [_vm._v(_vm._s(_vm.selectedReceiver.ic_number))])]), _c('div', {
    staticClass: "col-md-6 form-group"
  }, [_c('label', {
    attrs: {
      "for": ""
    }
  }, [_vm._v(_vm._s(_vm.$t("relationship")))]), _c('p', {
    staticClass: "form-control-static"
  }, [_vm._v(" " + _vm._s(_vm.selectedReceiver.relationship) + " ")])]), _c('div', {
    staticClass: "col-md-6 form-group",
    class: {
      'has-error': _vm.$v.form.portion.$error
    }
  }, [_c('label', {
    attrs: {
      "for": "part"
    }
  }, [_vm._v(_vm._s(_vm.$t("part")))]), _c('span', {
    staticClass: "text-danger"
  }, [_vm._v("*")]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model.trim",
      value: _vm.$v.form.portion.$model,
      expression: "$v.form.portion.$model",
      modifiers: {
        "trim": true
      }
    }],
    staticClass: "form-control",
    attrs: {
      "type": "text"
    },
    domProps: {
      "value": _vm.$v.form.portion.$model
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) { return; }
        _vm.$set(_vm.$v.form.portion, "$model", $event.target.value.trim());
      },
      "blur": function ($event) {
        return _vm.$forceUpdate();
      }
    }
  }), _vm.$v.form.portion.$error && !_vm.$v.form.portion.required ? _c('div', {
    staticClass: "form-error"
  }, [_vm._v(" " + _vm._s(_vm.$t("part")) + " " + _vm._s(_vm.$t("validation.cannot-blank")) + " ")]) : _vm._e()])]) : _vm._e()])]), _c('b-modal', {
    ref: "showUpdateModal",
    attrs: {
      "id": "showUpdateModal",
      "title": "$t('update-property-receiver')",
      "ok-title": "$t('save')",
      "cancel-title": "$t('cancel')"
    },
    on: {
      "ok": _vm.updatePropertyReceiver
    }
  }, [_c('div', {
    staticClass: "modal-body"
  }, [_c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-md-6 form-group"
  }, [_c('label', {
    attrs: {
      "for": ""
    }
  }, [_vm._v(_vm._s(_vm.$t("mykad")))]), _vm.formUpdate.receiver ? _c('p', {
    staticClass: "form-control-static"
  }, [_vm._v(" " + _vm._s(_vm.formUpdate.receiver.ic_number) + " ")]) : _vm._e()]), _c('div', {
    staticClass: "col-md-6 form-group"
  }, [_c('label', {
    attrs: {
      "for": ""
    }
  }, [_vm._v(_vm._s(_vm.$t("relationship")))]), _vm.formUpdate.receiver ? _c('p', {
    staticClass: "form-control-static"
  }, [_vm._v(" " + _vm._s(_vm.formUpdate.receiver.relationship) + " ")]) : _vm._e()]), _c('div', {
    staticClass: "col-md-6 form-group"
  }, [_c('label', {
    attrs: {
      "for": "portion"
    }
  }, [_vm._v(_vm._s(_vm.$t("part")))]), _c('span', {
    staticClass: "text-danger"
  }, [_vm._v("*")]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.formUpdate.portion,
      expression: "formUpdate.portion"
    }],
    staticClass: "form-control",
    attrs: {
      "type": "text"
    },
    domProps: {
      "value": _vm.formUpdate.portion
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) { return; }
        _vm.$set(_vm.formUpdate, "portion", $event.target.value);
      }
    }
  })])])])])], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }